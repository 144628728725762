import React from 'react';
import styled from 'styled-components';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';

import { Venue } from 'types/EditionTypes';
import { formatCountryInLocale } from 'lib/helpers';

interface VenueInfoProps {
    venue: Venue | null;
    lang: string | undefined;
}

export const VenueInfo: React.FC<VenueInfoProps> = (props) => {
    const { venue, lang } = props;

    if (!venue) return null;

    const { t } = useTranslation();

    const coordinates = {
        lat: venue?.latitude ?? 1,
        lng: venue?.longitude ?? 1,
    };

    // TODO : Should this be in .env ?
    const googleMapsApiKey = 'AIzaSyDremr8vljYVNT6oSEyq1PXisyTuRz0d3k';

    const mapStylesArray = [
        {
            featureType: 'all',
            elementType: 'labels.text',
            stylers: [
                {
                    color: '#878787',
                },
            ],
        },
        {
            featureType: 'all',
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [
                {
                    color: '#f9f5ed',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'all',
            stylers: [
                {
                    color: '#f5f5f5',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    color: '#c9c9c9',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'all',
            stylers: [
                {
                    color: '#aee0f4',
                },
            ],
        },
    ];

    const mapOptions = {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles: mapStylesArray,
    };

    const { name, address, zip, city, country_code } = venue;

    return (
        <>
            <StyledTitle>{t('venue_info.title')}</StyledTitle>
            <StyledInfo>
                <h5>{name}</h5>
                {address && <p>{address}</p>}
                <p>
                    {zip} {city}
                    {city && country_code ? ',' : ''} {formatCountryInLocale(country_code, lang)}
                </p>
            </StyledInfo>
            <StyledGoogleMaps>
                <LoadScript googleMapsApiKey={googleMapsApiKey}>
                    <GoogleMap
                        mapContainerClassName="maps-container"
                        center={coordinates}
                        zoom={9}
                        options={mapOptions}
                    >
                        <Marker position={coordinates} />
                    </GoogleMap>
                </LoadScript>
            </StyledGoogleMaps>
        </>
    );
};

const StyledTitle = styled.h3`
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 1rem;
`;

const StyledGoogleMaps = styled.div`
    width: 100%;
    padding-top: 66.66%;
    position: relative;
    border-radius: 0.25rem;
    overflow: hidden;
    margin-bottom: 1.5rem;

    .maps-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    div,
    img {
        border-radius: 0 !important;
    }
`;

const StyledInfo = styled.div`
    margin-bottom: 1rem;

    color: ${(props) => props.theme.colors.fontMedium};

    h5 {
        margin-bottom: 0.25rem;
        font-weight: 600;
    }
    p {
        line-height: 1.2;
    }
`;
